
import ErrorMessages from '@/components/Messages.vue'
import { EmployeeService } from '@/services'
import { Campaign, Employee } from '@/types'
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'ListEmployees',
  components: {
    ErrorMessages,
  },
})
export default class ListEmployeesView extends Vue {
  loadingEmployees = false
  allEmployees: Employee[] = []

  sortBy = 'firstName'
  sortReverse = false

  showFilterInput = false
  filter = {
    empNo: '',
    firstName: '',
    lastName: '',
    campaign: null as Campaign | null,
  }

  mounted(): void {
    this.fetchEmployees().then((employees: Employee[]) => {
      this.allEmployees = employees
    })
  }

  async refresh(): Promise<void> {
    this.allEmployees = []
    this.allEmployees = await this.fetchEmployees()
  }

  async fetchEmployees(): Promise<Employee[]> {
    try {
      this.loadingEmployees = true
      return await EmployeeService.fetchEmployees()
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as ErrorMessages).pushErr(err)
      return []
    } finally {
      this.loadingEmployees = false
    }
  }

  selectEmployee(employee: Employee): void {
    this.$router.push({ name: 'ShowEmployee', params: { employeeNumber: employee.employeeNumber } })
  }

  sort(columnName: string): void {
    if (columnName === this.sortBy) {
      this.sortReverse = !this.sortReverse
    } else {
      this.sortBy = columnName
      this.sortReverse = false
    }
  }

  get employees(): Employee[] {
    let employees = this.allEmployees

    if (this.showFilterInput) {
      if (this.filter.campaign !== null) {
        employees = employees.filter((row) => {
          return (
            row.campaignAssignments
              .map((ca) => ca.campaign)
              .flat()
              .filter((c) => c && c.id === this.filter.campaign?.id).length >= 1
          )
        })
      }

      employees = employees.filter((row) => {
        const filterEmpNo = this.filter.empNo.trim()

        const filterFirstName = this.filter.firstName.trim().toLocaleLowerCase()
        const firstName = row.firstName?.toLocaleLowerCase() ?? ''

        const filterLastName = this.filter.lastName.trim().toLocaleLowerCase()
        const lastName = row.lastName?.toLocaleLowerCase() ?? ''

        return (
          row.employeeNumber.toString().startsWith(filterEmpNo) &&
          firstName.includes(filterFirstName) &&
          lastName.includes(filterLastName)
        )
      })
    }

    return employees.sort((a: Employee, b: Employee) => {
      const modifier = this.sortReverse ? -1 : 1

      switch (this.sortBy) {
        case 'firstName':
          return (a.firstName ?? '').localeCompare(b.firstName ?? '', 'sv-SE') * modifier
        case 'lastName':
          return (a.lastName ?? '').localeCompare(b.lastName ?? '', 'sv-SE') * modifier
        case 'empNo':
          return a.employeeNumber > b.employeeNumber ? modifier : -modifier
      }

      return 0
    })
  }

  get uniqueCampaigns(): Campaign[] {
    if (!this.allEmployees) {
      return []
    }

    return this.allEmployees
      .map((e) => e.campaignAssignments)
      .flat()
      .map((ca) => ca.campaign ?? [])
      .flat()
      .filter((e, i, arr) => arr.findIndex((s) => e.id === s.id) === i)
      .sort((a, b) => a.name.localeCompare(b.name))
  }
}
